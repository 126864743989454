import React from "react";


const About = () => {
    return (

        <>
            <div class="container-fluid py-4 bg-success-subtle mt-5">

                <div class="container bg-secondary text-white py-5 px-5 my-4 rounded-top border-warning border border-4">

                    <p class="fs-2 text-center">Hey!</p>

                    <h1 class="fs-1 fw-normal text-center">About Trade Pro Solution</h1>

                    <p class="fs-4 fw-normal">WE ARE On A Mission To Help People Succeed In Stock Market, We Have Conducted Over 100+ Stock Market
                        Events, Mentored 4000+ People & Delivered Masterclass To Over 55000+ People.
                    </p>

                    <p class="fs-4 fw-normal">We Guided over 4000+ people And Helped them become sound in the stock market by understanding the
                        concepts using order flow analysis which helps them give a deep insight into the market during live
                        markets.
                    </p>

                    <p class="fs-4 fw-normal">This led us to form a vision of helping people learn and understand the stock market, with the market
                        changing dynamically the approach towards decision making in the stock market must also be dynamic this
                        brings in the concept of order flow which involves decision making during the live market.
                    </p>
                </div>
            </div>

        </>
    );
};

export default About;
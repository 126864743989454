import React from "react";
import Welcome from "./Welcome";
import Slider from "./Slider";
import Card from "./Card";
import TradingViewWedget from "./TradingViewWidget";
import Chart from "./Chart";


const Home = () => {
    return (
        <>
            <div>
                
                <Welcome />
                <Slider />
                <Card />
                <TradingViewWedget/>
                <Chart/>
                
            </div>


        </>
    )
}

export default Home;
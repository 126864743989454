import React, { useState } from 'react';
//import axios from 'axios';

const Welcome = () => {

    const [name,setName] = useState('');
    const [email, setEmail] = useState('');
    const [mobile, setMobile] = useState('');
    const [error, setError] = useState('');
    
   

    // Function to handle form submission
    const handleSubmit = async (e) => {
        e.preventDefault(); // Prevent form refresh

        // Simple validation
        if (!email || !name || !mobile) {
            setError('All Fields are required.');
            return;
        }

        // Reset error and message states
        setError('');
       


        

/*

        try {
            // Make a POST request to the login API endpoint
            axios.get('#')
                .then((response) => {
                   
                })



            // Handle different response scenarios
           
        } catch (error) {
            setError('An error occurred. Please try again.');
        }
*/

    };

    return (
        <>
            <div class="container-fluid  px-4 py-6 mt-5 bg-dark ">
                <div class="row align-items-center g-lg-5 py-5 ms-4 me-4">
                    <div class="col-lg-7 text-center text-lg-start ">
                        <h1 class="display-4 fw-bold lh-1 text-light mb-3 ">Welcome to Trade Pro Solution</h1>
                        <p class="col-lg-10 fs-4 text-light">"It's not whether you're right or wrong that's important, but how
                            much money you make when you're right and how much you lose when you're wrong." — George Soros</p>
                    </div>
                    <div class="col-md-10 mx-auto col-lg-4 ">
                        <h2 className="text-light text-center">Contact Us</h2>
                        {error && <p style={styles.error}>{error}</p>}
                        <form onSubmit={handleSubmit} className="p-4 p-md-5 border rounded-3 bg-body-tertiary" > 

                            <div class="form-floating mb-3">
                                <input type="text" class="form-control" id="floatingPassword" placeholder="Full name" value={name} onChange={(e) => setName(e.target.value)} />
                                    <label for="floatingPassword">Full name</label>
                            </div>
                            <div class="form-floating mb-3">
                            <input type="email" class="form-control" id="floatingPassword" placeholder="Email Address" value={email} onChange={(e) => setEmail(e.target.value)} />
                                    <label for="floatingInput">Email address</label>
                            </div>
                            <div class="form-floating mb-3">
                            <input type="text" class="form-control" id="floatingPassword" placeholder="Mobile No." value={mobile} onChange={(e) => setMobile(e.target.value)} />
                                    <label for="floatingPassword">Mobile No.</label>
                            </div>

                            <div class="checkbox mb-3">
                                <label>
                                    <input type="checkbox" value="remember-me"/> Remember me
                                </label>
                            </div>
                            <button class="w-100 btn btn-lg btn-primary" type="submit">Sign up</button>
                            <hr class="my-4" />
                            <small class="text-body-secondary">By clicking Sign up, you agree to the terms of use.</small>
                        </form>
                    </div>
                </div>
            </div>
        </>

    );

};

const styles = {
    container: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'start',
      height: '350px',
      width:'300px',
      backgroundColor: 'dark',
      position:'relative',
      top:'-350px',
      left:'900px',
    },
    form: {
      display: 'flex',
      flexDirection: 'column',
      width: '300px',
      padding: '20px',
      border: '1px solid #ccc',
      borderRadius: '10px',
      backgroundColor: '#fff',
    },
    inputGroup: {
      marginBottom: '15px',
    },
    input: {
      width: '100%',
      padding: '10px',
      fontSize: '16px',
      borderRadius: '5px',
      border: '1px solid #ccc',
    },
    button: {
      padding: '10px',
      backgroundColor: '#007bff',
      color: '#fff',
      border: 'none',
      borderRadius: '5px',
      cursor: 'pointer',
    },
    error: {
      color: 'red',
      marginBottom: '10px',
    },
    success: {
      color: 'green',
      marginBottom: '10px',
    },
  };

export default Welcome;
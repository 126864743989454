import React from "react";
import { Link } from 'react-router-dom';


const Footer = () => {
    return (
        <>


            <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class=" modal-dialog modal-dialog-centered">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h1 class="modal-title fs-5" id="exampleModalLabel">Disclaimer</h1>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            <span>Welcome to Trade Pro Solution!</span>
                            <h6>Risk Disclosure</h6>
                            <p>The information provided is for educational purposes only. Our intention is not to provide any financial advice, investment advice, trading advice, or any other advice.</p>
                            <p>This is general information & is not explicit to you or anyone else do not take any action unless you are set up to continue an <strong>“ALL OUT MISFORTUNE” or “TOTAL LOSS”.</strong></p>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                        </div>
                    </div>
                </div>
            </div>



            <div class="container-fluid text-center bg-dark pt-4 ">
                <div class="row">
                    <div class="col col-xl-4 col-md-2 mb-3 text-light">
                        <h5>Our Policies</h5>
                        <ul class="nav flex-column">
                            <li class="nav-item mb-2"><a href="#" class="nav-link p-0 text-light">Terms & Conditions</a>
                            </li>
                            <li class="nav-item mb-2"><a href="#" class="nav-link p-0 text-light">Privacy Policy</a>
                            </li>
                            <li class="nav-item mb-2"><a class="nav-link p-0 text-light " data-bs-toggle="modal" data-bs-target="#exampleModal">Disclaimer</a></li>

                        </ul>
                    </div>
                    <div class="col col-xl-4 col-md-2 mb-3 text-light">
                        <h5>Company</h5>
                        <ul class="nav flex-column">
                            <li class="nav-item mb-2"><Link to="/" class="nav-link p-0 text-light">Home</Link></li>
                            <li class="nav-item mb-2"><Link to="/about" class="nav-link p-0 text-light">About us</Link></li>
                            <li class="nav-item mb-2"><Link to="#" class="nav-link p-0 text-light">Service</Link></li>
                            <li class="nav-item mb-2"><Link to="/contact" class="nav-link p-0 text-light">Contact us</Link></li>

                        </ul>
                    </div>
                    <div class="col col-xl-4 col-md-2 mb-3 text-light">
                        <h5>Contact</h5>
                        <ul class="nav flex-column">
                            <li class="nav-item mb-2">Delhi | Varanasi | Patna </li>
                            <li class="nav-item mb-2">+91 9235757861</li>
                            <li class="nav-item mb-2">tomaraakash503@gmail.com</li>


                        </ul>
                    </div>
                </div>
                <div class="d-flex flex-row flex-sm-row justify-content-center pt-2 border-top">
                    <p class="text-light">© 2024 Trade Pro Solution, Pvt Ltd. All rights reserved.</p>

                </div>
            </div>
        </>
    );
};

export default Footer;
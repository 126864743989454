import React from "react";
import Login from "./Login";

const Contact = () => {
    
    return (
        <>
            <div class="px-4 py-4 mt-5 text-center" style={mystyle.container}>

                <h1 class="display-3 fw-bold text-primary">Contact Us</h1>
                <div class="col-lg-6 mx-auto">

                    <p className="lead mb-4">We would be grateful if you could take a few moments to provide us with your feedback on your recent experience using our service.Your feedback will help us understand whatwe are doing well and where we can improve.</p>
                    <p className="lead mb-4" >To better serve you and other customers, we are constantly seeking feedback onour service and  how we can improve our user experience. That’s why we would like to hear from you regarding your recent experience.</p>
                </div>
            </div>
            <Login />
        </>
    );
};

let mystyle = {
    container:{
        background:'#db88ab',
    },
    
}

export default Contact;
import React from 'react';
import tm1 from './images/trading-image-1.jpg';
import tm2 from './images/trading-image-2.jpg';
import tm3 from './images/trading-image-3.jpg';
const Slider = () => {
    return (
        <>
            <div id="carouselExampleAutoplaying" class="carousel slide carousel-fade mt-1 " data-bs-ride="carousel">
                <div class="carousel-indicators">
                    <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="0" class="active"
                        aria-current="true" aria-label="Slide 1"></button>
                    <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="1"
                        aria-label="Slide 2"></button>
                    <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="2"
                        aria-label="Slide 3"></button>
                </div>
                <div class="carousel-inner">
                    <div class="carousel-item active">
                        <img src={tm1} class="d-block w-100" alt="..." />
                        <div class="carousel-caption d-none d-md-block">
                            <h5>Education</h5>
                            <p>Education is the best way to earn money.</p>
                        </div>
                    </div>
                    <div class="carousel-item">
                        <img src={tm2} class="d-block w-100" alt="..." />
                        <div class="carousel-caption d-none d-md-block">
                            <h5>Learn Stock Market</h5>
                            <p>If you pay attention on learning you definately earn money.</p>
                        </div>
                    </div>
                    <div class="carousel-item">
                        <img src={tm3} class="d-block w-100" alt="..." />
                        <div class="carousel-caption d-none d-md-block">
                            <h5>Intraday Training</h5>
                            <p>We provide intraday training.</p>
                        </div>
                    </div>
                </div>
                <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleAutoplaying"
                    data-bs-slide="prev">
                    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span class="visually-hidden">Previous</span>
                </button>
                <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleAutoplaying"
                    data-bs-slide="next">
                    <span class="carousel-control-next-icon" aria-hidden="true"></span>
                    <span class="visually-hidden">Next</span>
                </button>
            </div>

            

        </>
    );
};

export default Slider;
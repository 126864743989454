import React from "react";
import d0 from './images/demat-account-0.jpg';
import d1 from './images/demat-account-1.jpg';
import d2 from './images/demat-account-2.jpg';

const Card = () => {
    return (
        <>
            <div class="container mt-2">
                <h3 className="fw-bold mt-4 mb-4 text-center">Why you choose Trade Pro Solution</h3>
               
                <div class="row row-cols-1 row-cols-md-3 g-4">
                    <div class="col">
                        <div class="card h-100">
                            <img src={d0} class="card-img-top" alt="..."/>
                                <div class="card-body">
                                    <h3 class="card-title">Trading & Demat Ac Opening</h3>
                                    <ul>
                                        <li>We have Tie-Up with major Stock brokers in India.</li>
                                        <li>Open a Trading And demat Account with us and get many of Our Services in Free and
                                            Discounted
                                            Price.</li>
                                        <li>We have Tie-Up with"Zerodha,AngelOne,Swastika,Fyres,
                                            AliceBlue,MotilalOswal"</li>
                                    </ul>
                                </div>
                                <div class="card-footer">
                                    <small class="text-body-secondary"></small>
                                </div>
                        </div>
                    </div>
                    <div class="col">
                        <div class="card h-100">
                            <img src={d1} class="card-img-top" alt="..."/>
                                <div class="card-body">
                                    <h3 class="card-title">Buy Sell Signal Indicators</h3>
                                    <ul>
                                        <li>We are Providing Buy Sell Indicators on Trading View Plateform.</li>
                                        <li>Using our Premium indicators you can easily Guess market Trend and can take wise
                                            decisons for Profitable Trading.</li>
                                        <li>After purchasing Our Indicators you will get Proper training to utilise it.</li>
                                    </ul>
                                </div>
                                <div class="card-footer">
                                    <small class="text-body-secondary"></small>
                                </div>
                        </div>
                    </div>
                    <div class="col">
                        <div class="card h-100">
                            <img src={d2} class="card-img-top" alt="..."/>
                                <div class="card-body">
                                    <h3 class="card-title">Stock Market Education And Training</h3>
                                    <ul>
                                        <li>We are Providing Stock Market Training Based on our Own Experiance of 12 Years in
                                            Market.</li>
                                        <li>After Our Training Cources you may able to judge market direction and able to take right
                                            trading decisions</li>
                                        <li>We have also provide the full Cource of option chain analysis.</li>
                                        <li>We have better explain the greeks of option chain</li>
                                        <li>The greeks are a set of four theoretical values that discribe and option's risk
                                            parameters.</li>
                                        <ol>
                                            <li>Delta</li>
                                            <li>Gamma</li>
                                            <li>Theta</li>
                                            <li>Vega</li>
                                        </ol>
                                    </ul>
                                </div>
                                <div class="card-footer">
                                    <small class="text-body-secondary"></small>
                                </div>
                        </div>
                    </div>
                </div>

            </div>

            <div class="container-flude bg-danger mt-4 text-light py-2 mx-5">
                <h4>
                    <marquee behavior="" direction="left">We are the best institute for proving trading knowledge.</marquee>
                </h4>
            </div>
        </>
    );
};

export default Card;
// Login.js
import React, { useState } from 'react';
//import axios from 'axios';

const Login = () => {
  // State for storing email, password, and response status
  const [email, setEmail] = useState('');
  const [mobile, setMobile] = useState('');
  const [name, setName] = useState('');
  const [error, setError] = useState('');
 
 

  // Function to handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevent form refresh

    // Simple validation
    if (!email || !name || !mobile) {
      setError('All fields are required.');
      return;
    }

    // Reset error and message states
    setError('');
   
    
    
 

    /*

    try {
      // Make a POST request to the login API endpoint
      axios.get('#')
        .then((response) => {
          
      })


      
      // Handle different response scenarios
      
    } catch (error) {
      setError('An error occurred. Please try again.');
    }
*/
    
  };

  return (
    <div className="login-container" style={styles.container}>
      <h2 className="text-dark display-6 fw-bold">Contact Us</h2>
      {error && <p style={styles.error}>{error}</p>}
      <form onSubmit={handleSubmit} style={styles.form}>
      <div style={styles.inputGroup}>
          <input
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
            placeholder="Enter your FullName"
            style={styles.input}
           
          />
        </div>

        <div style={styles.inputGroup}>
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Enter your email"
            style={styles.input}
           
          />
        </div>
        <div style={styles.inputGroup}>
         
          <input
            type="text"
            value={mobile}
            onChange={(e) => setMobile(e.target.value)}
            placeholder="Enter your Mobile No"
            style={styles.input}
            
          />
        </div>
        <button type="submit" style={styles.button}>
          Submit
        </button>
      </form>
    </div>
  );
};

// Basic inline styles for simplicity
const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'start',
    height: '350px',
    backgroundColor:'#89dcf5',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    width: '300px',
    padding: '20px',
    border: '1px solid #ccc',
    borderRadius: '10px',
    backgroundColor: '#fff',
  },
  inputGroup: {
    marginBottom: '15px',
  },
  input: {
    width: '100%',
    padding: '10px',
    fontSize: '16px',
    borderRadius: '5px',
    border: '1px solid #ccc',
  },
  button: {
    padding: '10px',
    backgroundColor: '#007bff',
    color: '#fff',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
  },
  error: {
    color: 'red',
    marginBottom: '10px',
  },
  success: {
    color: 'green',
    marginBottom: '10px',
  },
};

export default Login;

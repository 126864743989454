// src/components/TradingViewWidget.js

import React, { useEffect, useRef } from 'react';

const TradingViewWidget = () => {
    const containerRef = useRef(null);

    useEffect(() => {
        const script = document.createElement('script');
        script.src = 'https://s3.tradingview.com/tv.js';
        script.async = true;

        script.onload = () => {
            new window.TradingView.widget({
                symbol: "NASDAQ:AAPL", // change this symbol to your desired one
                autosize: true,
                container_id: "tradingview-widget",
                theme: "light",
                style: "1",
                locale: "en",
                toolbar_bg: "#f1f3f6",
                enable_publishing: false,
                allow_symbol_change: true,
            });
        };

        containerRef.current.appendChild(script);
    }, []);

    return (
        <>
            <div className="container tradingview-widget-container mt-4" ref={containerRef}>
                <h1 class="fw-bold text-center">Market Watch</h1>
                <div id="tradingview-widget" style={{ height: "500px" }}></div>
            </div>
       
            <div class="container-flude bg-danger mt-4 text-light py-2 mx-5">
                <h4>
                    <marquee behavior="" direction="left">We are the best institute for proving trading knowledge.</marquee>
                </h4>
            </div>
        </>
    );
};

export default TradingViewWidget;

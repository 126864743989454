import './App.css';
import About from './About';
import Home from './Home';
import Contact from './Contact';
import Navebar from './Navebar'
import Footer from './Footer'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Routes,
} from "react-router-dom";




function App() {
  return (
    <>
    
      <Router>
      <Navebar/>
        <Routes>
          <Route path='/' element={<Home/>}/>
          <Route path='/about' element={<About/>}/>
          <Route path='/contact' element={<Contact/>}/>
        </Routes>
        <Footer/>
       
      </Router>
    
    </>
  );
}

export default App;


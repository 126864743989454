// Login.js
import React from 'react';
import { Link } from 'react-router-dom';


const Navebar = () => {
  
  return (
    <>

    <nav class="navbar navbar-expand-lg navbar-expand-sm navbar-expand-md navbar-expand-xl  navbar-dark bg-dark fixed-top">
        <div class="container-fluid ">
            <a class="navbar-brand" href="#">Trade Pro Solution</a>
            <button class="navbar-toggler" type="button" data-bs-toggle="collapse"
                data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false"
                aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
            </button>
            <div class="collapse navbar-collapse " id="navbarSupportedContent">
                <ul class="navbar-nav me-auto mb-2 mb-lg-0 ">
                    <li class="nav-item ">
                        <Link class="nav-link active" aria-current="page" to="/">Home</Link>
                    </li>
                    <li class="nav-item">
                        <Link class="nav-link" to="/about">About</Link>
                    </li>
                    <li class="nav-item dropdown">
                        <a class="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown"
                            aria-expanded="false">
                            Service
                        </a>
                        <ul class="dropdown-menu">
                            <li><a class="dropdown-item" href="#">Demat Account Opening</a></li>
                            <li><a class="dropdown-item" href="#">Option Trading</a></li>
                            <li>
                                <hr class="dropdown-divider"/>
                            </li>
                            <li><a class="dropdown-item" href="#">Insurance</a></li>
                            <li><a class="dropdown-item" href="#">Stock Analysis</a></li>
                            <li><a class="dropdown-item" href="#">Buy & Sell Indicator</a></li>
                        </ul>
                    </li>
                    <li class="nav-item">
                        <Link class="nav-link" to="/contact">Contact</Link>
                    </li>
                </ul>

            </div>
        </div>
    </nav>
    </>
    
  );
};



export default Navebar;

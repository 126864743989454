import React from "react";

var TxtType = function (el, toRotate, period) {
    this.toRotate = toRotate;
    this.el = el;
    this.loopNum = 0;
    this.period = parseInt(period, 10) || 2000;
    this.txt = '';
    this.tick();
    this.isDeleting = false;
};

TxtType.prototype.tick = function () {
    var i = this.loopNum % this.toRotate.length;
    var fullTxt = this.toRotate[i];

    if (this.isDeleting) {
        this.txt = fullTxt.substring(0, this.txt.length - 1);
    } else {
        this.txt = fullTxt.substring(0, this.txt.length + 1);
    }

    this.el.innerHTML = '<span class="wrap">' + this.txt + '</span>';

    var that = this;
    var delta = 200 - Math.random() * 100;

    if (this.isDeleting) { delta /= 2; }

    if (!this.isDeleting && this.txt === fullTxt) {
        delta = this.period;
        this.isDeleting = true;
    } else if (this.isDeleting && this.txt === '') {
        this.isDeleting = false;
        this.loopNum++;
        delta = 500;
    }

    setTimeout(function () {
        that.tick();
    }, delta);
};

window.onload = function () {
    var elements = document.getElementsByClassName('typewrite');
    for (var i = 0; i < elements.length; i++) {
        var toRotate = elements[i].getAttribute('data-type');
        var period = elements[i].getAttribute('data-period');
        if (toRotate) {
            new TxtType(elements[i], JSON.parse(toRotate), period);
        }
    }
    // INJECT CSS
    var css = document.createElement("style");
    css.type = "text/css";
    css.innerHTML = ".typewrite > .wrap { border-right: 0.08em solid #fff}";
    document.body.appendChild(css);
};

const Chart = () => {
    return (

        <>
            <div class="container bg-dark mt-4 pb-4 mb-4">

                <h1 class="text-light text-center pt-3 ">What we do?</h1>

                <div class="row ">
                    <div class=" container col-xl-6 col-xs-6 text-light text-end " style={styles.cont}>
                        <span class="mt-2 fw-bold fs-2">Open your free</span>
                    </div>
                    <div class="container text-start col-xs-6 col-xl-6">
                        <h1>
                            <a href="" class="typewrite text-light" data-period="2000"
                                data-type='[ "demate", "account", "now" ]'>
                                <span class="wrap"></span>
                            </a>
                        </h1>
                    </div>
                </div>

                <div class="container border-2 border-bottom my-5"></div>

                <div class="container bg-dark text-light mt-2 mb-2 py-4 fs-4 fw-medium">
                    <h5 class="text-light text-center mb-4 fw-bold">DOCUMENT FOR ACCOUNT OPENING</h5>
                    <div className="row">
                        <div className="col border-end">
                            <span>
                                AADHAR CARD<br />
                                (FRONT AND BACK)<br />
                                &<br />
                                PAN CARD
                            </span>
                        </div>
                        <div className="col border-end">
                            MOTHER NAME<br />
                            &<br />
                            EMAIL
                        </div>
                        <div className="col border-end">
                            PHONE NO<br />
                            &<br />
                            6 MONTHS BANK<br />
                            STATEMENT
                        </div>

                        <div className="col">
                            SIGNATURE<br />
                            &<br />
                            PASSPORT SIZE<br />
                            PHOTO
                        </div>

                    </div>
                </div>


            </div>
        </>
    );
};

const styles = {
    cont: {
      
    },
   
  };
  
 
  

export default Chart;